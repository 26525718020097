import * as React from "react";
import Layout from "../components/global/Layout";
import Hero from "../components/prostate-cancer/Hero";
import BlockOne from "../components/prostate-cancer/BlockOne";
import BlockTwo from "../components/prostate-cancer/BlockTwo";
import BlockThree from "../components/prostate-cancer/BlockThree";
import BlockFour from "../components/prostate-cancer/BlockFour";
import BlockSix from "../components/prostate-cancer/BlockSix";
import { useProstateCancerQuery } from "../hooks/useProstateCancerQuery";
import Helmet from "react-helmet";

const ProstateCancerPage = () => {
    const data = useProstateCancerQuery()
    
    return (
        <Layout>
            <Hero data={data.prostateCancerHero} />
            <BlockOne data={data.prostateCancerBlock1} />
            <BlockTwo data={data.prostateCancerBlock2} />
            <BlockThree data={data.prostateCancerBlock3} />
            <BlockFour data={data.prostateCancerBlock4} />
            <BlockSix  data={data.prostateCancerBlock6} />
            <Helmet>
                <title>{data.prostateCancerMetaData.metaTitle}</title>
                <meta name="description" content={data.prostateCancerMetaData.metaDescription} />
            </Helmet>
        </Layout>
    )
}

export default ProstateCancerPage;