import { useStaticQuery, graphql } from "gatsby"

export const useProstateCancerQuery = () => {


    const data = useStaticQuery(graphql`
    query ProstateCancerQuery {
        wpPage(title: {eq: "PROSTATE CANCER"}) {
            ACF_ProstateCancerPage {

            prostateCancerHero {
                text
                image{
                    publicUrl
                }
                tagline
            }

            prostateCancerBlock1 {
                content
            }

            prostateCancerBlock2 {
                tagline 
                column1{
                    title
                    content
                }
                column2{
                    title
                    content
                }
                column3{
                    title
                    content
                }
                column4{
                    title
                    content
                }
            }

            prostateCancerBlock3 {
                tagline
                column1 {
                    image {
                        publicUrl
                    }
                    title
                    content
                }
                column2 {
                    image {
                        publicUrl
                    }
                    title
                    content
                }
            }

            prostateCancerBlock4 {
                image {
                    publicUrl
                }

                content
            }

            prostateCancerBlock6 {
                content
            }

            prostateCancerMetaData {
                metaTitle
                metaDescription
            }
        
        }
        }
    }     
    `)
    return data.wpPage.ACF_ProstateCancerPage;
}