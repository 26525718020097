import * as React from "react";

const BlockFour = ({data}) => {
    return (
        <div>
            <div className="row-fluid-wrapper row-depth-1 row-number-7 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-custom_widget " style={{height: "60px"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                        <div id="hs_cos_wrapper_module_1555937212563844" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                    </div>
                </div>
            </div>

            <div className="row-fluid-wrapper row-depth-1 row-number-8 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                        <div className="row-fluid-wrapper row-depth-1 row-number-9 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-cell full-width sp_home_steps_outer" style={{backgroundImage: "url('"+data.image.publicUrl+"')", backgroundSize: "cover", backgroundPosition: "right top", backgroundRepeat: "no-repeat", position: "relative"}} data-widget-type="cell" data-x="0" data-w="12">

                                    <div className="row-fluid-wrapper row-depth-1 row-number-10 ">
                                        <div className="row-fluid ">
                                            <div className="span5 widget-span widget-type-cell sp_home_steps" style={{marginLeft: "80px"}} data-widget-type="cell" data-x="0" data-w="5">

                                                <div className="row-fluid-wrapper row-depth-1 row-number-11 ">
                                                    <div className="row-fluid ">
                                                        <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{height: "60px"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                                                            <div id="hs_cos_wrapper_module_1555936866745685" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row-fluid-wrapper row-depth-1 row-number-12 ">
                                                    <div className="row-fluid ">
                                                        <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                            <div id="hs_cos_wrapper_module_1555936866745686" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row-fluid-wrapper row-depth-1 row-number-13 ">
                                                    <div className="row-fluid ">
                                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                            <div id="hs_cos_wrapper_module_1555936866745687" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1555936866745687_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
                                                                <div dangerouslySetInnerHTML={{__html: data.content}}></div>
                                                                </span></div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row-fluid-wrapper row-depth-1 row-number-14 ">
                                                    <div className="row-fluid ">
                                                        <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                            <div id="hs_cos_wrapper_module_1555936866745689" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="span7 widget-span widget-type-cell " data-widget-type="cell" data-x="5" data-w="7">

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row-fluid-wrapper row-depth-1 row-number-15 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{height:"80px"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_1555936866745691" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockFour;