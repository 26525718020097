import * as React from "react";

const BlockTwo = ({data}) => {
    return (
        <div className="row-fluid-wrapper row-depth-1 row-number-14 ">
            <div className="row-fluid ">
                <div className="span12 widget-span widget-type-cell section--grid bg--gray" data-widget-type="cell" data-x="0" data-w="12">

                    <div className="row-fluid-wrapper row-depth-1 row-number-15 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-cell container" data-widget-type="cell" data-x="0" data-w="12">

                                <div className="row-fluid-wrapper row-depth-1 row-number-16 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_1536676302657145" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1536676302657145_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h2 className="headlines" style={{ textAlign: "center" }} dangerouslySetInnerHTML={{__html: data.tagline}}></h2></span></div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-17 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-cell grid" data-widget-type="cell" data-x="0" data-w="12">

                                            <div className="row-fluid-wrapper row-depth-1 row-number-18 ">
                                                <div className="row-fluid ">
                                                    <div className="span3 widget-span widget-type-cell grid-item" data-widget-type="cell" data-x="0" data-w="3">

                                                        <div className="row-fluid-wrapper row-depth-2 row-number-1 ">
                                                            <div className="row-fluid ">
                                                                <div className="span12 widget-span widget-type-custom_widget grid-item--content" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                    <div id="hs_cos_wrapper_module_153667419655956" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_153667419655956_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h3 className="headlines" style={{ textAlign: "center" }} dangerouslySetInnerHTML={{__html: data.column1.title}}></h3>
                                                                        <div dangerouslySetInnerHTML={{__html: data.column1.content}}></div></span></div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="span3 widget-span widget-type-cell grid-item" data-widget-type="cell" data-x="3" data-w="3">

                                                        <div className="row-fluid-wrapper row-depth-2 row-number-2 ">
                                                            <div className="row-fluid ">
                                                                <div className="span12 widget-span widget-type-custom_widget grid-item--content" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                    <div id="hs_cos_wrapper_module_153667420195660" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_153667420195660_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h3 className="headlines" style={{ textAlign: "center" }} dangerouslySetInnerHTML={{__html: data.column2.title}}></h3>
                                                                        <div dangerouslySetInnerHTML={{__html: data.column2.content}}></div></span></div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="span3 widget-span widget-type-cell grid-item" data-widget-type="cell" data-x="6" data-w="3">

                                                        <div className="row-fluid-wrapper row-depth-2 row-number-3 ">
                                                            <div className="row-fluid ">
                                                                <div className="span12 widget-span widget-type-custom_widget grid-item--content" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                    <div id="hs_cos_wrapper_module_153667420456864" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_153667420456864_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h3 className="headlines" style={{ textAlign: "center" }} dangerouslySetInnerHTML={{__html: data.column3.title}}></h3>
                                                                        <div dangerouslySetInnerHTML={{__html: data.column3.content}}></div></span></div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="span3 widget-span widget-type-cell grid-item" data-widget-type="cell" data-x="9" data-w="3">

                                                        <div className="row-fluid-wrapper row-depth-2 row-number-4 ">
                                                            <div className="row-fluid ">
                                                                <div className="span12 widget-span widget-type-custom_widget grid-item--content" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                    <div id="hs_cos_wrapper_module_153667420717868" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_153667420717868_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h3 className="headlines" style={{ textAlign: "center" }} dangerouslySetInnerHTML={{__html: data.column4.title}}></h3>
                                                                        <div dangerouslySetInnerHTML={{__html: data.column4.content}}></div></span></div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BlockTwo;