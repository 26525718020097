import * as React from "react";

const BlockThree = ({data}) => {
    return (
        <div>
            <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-cell full-width" data-widget-type="cell" data-x="0" data-w="12">

                        <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                    <div id="hs_cos_wrapper_module_1536677581242184" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                </div>
                            </div>
                        </div>

                        <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                    <div id="hs_cos_wrapper_module_1536677932277227" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1536677932277227_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h2 className="headlines" style={{ textAlign: "center" }} dangerouslySetInnerHTML={{__html: data.tagline}}></h2></span></div>

                                </div>
                            </div>
                        </div>

                        <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                    <div id="hs_cos_wrapper_module_1536677938193229" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                        <div className="row-fluid-wrapper row-depth-1 row-number-6 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                    <div id="hs_cos_wrapper_module_15559058255883254" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="locatorsWrapper">

                                        <div className="locatorsBoxes">
                                            <img style={{ width: "100%" }} src={data.column1.image.publicUrl} />
                                            <div className="locatorsContent clearfix nopadding"><h3 style={{ textAlign: "center" }}><span style={{ color: "#ffffff" }} dangerouslySetInnerHTML={{__html:data.column1.title}}></span></h3>
                                                <div className="sp_home_cta_copy" style={{ textAlign: "center" }} dangerouslySetInnerHTML={{__html: data.column1.content}}>
                                                </div></div>
                                        </div>

                                        <div className="locatorsBoxes">
                                            <img style={{ width: "100%" }} src={data.column2.image.publicUrl} />
                                            <div className="locatorsContent clearfix nopadding"> <h3 style={{ textAlign: "center" }}><span style={{ color: "#ffffff" }} dangerouslySetInnerHTML={{__html:data.column2.title}}></span></h3>
                                                <div className="sp_home_cta_copy" style={{ textAlign: "center" }} dangerouslySetInnerHTML={{__html: data.column2.content}}>
                                                </div> </div>
                                        </div>

                                    </div></div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockThree;